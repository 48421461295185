<!--
 * @Author: wxy
 * @Describe: 
 * @LastEditors: wxy
 * @LastEditTime: 2021-06-21 21:49:08
-->
<template>
  <a-row :gutter="20" class="margin-bottom" type="flex" align="middle">
    <a-col>
      <a-button
        :disabled="tableLoading"
        type="primary"
        @click="refresh(filterState)"
        >刷新</a-button
      >
    </a-col>
    <a-col>
      <a-button
        :disabled="tableLoading"
        type="primary"
        @click="exortTable('养牛小程序-地址管理数据导出', filterState)"
        >导出</a-button
      >
    </a-col>
    <a-col>
      <a-input
        v-model:value="filterState.userName"
        placeholder="请输入用户名"
        allow-clear
      />
    </a-col>

    <a-col>
      <a-input
        v-model:value="filterState.userId"
        placeholder="请输入用户ID"
        allow-clear
      />
    </a-col>

    <a-col>
      <a-date-picker
        v-model:value="filterState.winningTime"
        placeholder="请选择中奖时间"
        valueFormat="yyyy-MM-DD"
      />
    </a-col>

    <a-col>
      <a-select
        style="width: 180px"
        v-model:value="filterState.winningType"
        placeholder="请选择中奖类型"
        allow-clear
        :options="[
          { value: 0, label: '排名奖励' },
          { value: 1, label: '集装扮奖励' },
          { value: 2, label: '盒子(DHA)' },
          { value: 3, label: '盒子(新春福袋)' },
        ]"
      >
      </a-select>
    </a-col>

    <a-col>
      <a-button
        :disabled="tableLoading"
        type="primary"
        @click="filterFn(search)"
        >搜索</a-button
      >
    </a-col>
  </a-row>
  <a-table
    :dataSource="dataSourceRef"
    :columns="columns"
    :loading="tableLoading"
    :pagination="pagination"
    :scroll="{
      x: 1000,
      y: 600,
      scrollToFirstRowOnChange: true,
    }"
    @change="tableChange"
  >
    <template #action="{ record }">
      <span>
        <a @click="openModal(record)">上传快递单号</a>
      </span>
    </template>
  </a-table>

  <!-- 单号输入 -->
  <a-modal v-model:visible="modalVisible" title="上传快递单号">
    <a-form>
      <a-form-item name="trackingNumber" v-bind="validateInfos.trackingNumber">
        <a-input
          allow-clear
          v-model:value="formState.trackingNumber"
          placeholder="请在此处输入快递单号"
        />
      </a-form-item>
    </a-form>
    <template #footer>
      <a-button key="back" @click="modalClose">取 消</a-button>
      <a-button key="submit" type="primary" @click="modalOk">上 传</a-button>
    </template>
  </a-modal>
</template>

<script>
import useTable from "@/composition/useTable";
import useTableFilter from "@/composition/useTableFilter";
import { ref, reactive } from "vue";
import { addTrackingNumber } from "@/common/http/api";

import { Form, message } from "ant-design-vue";
const useForm = Form.useForm;
export default {
  setup() {
    const modalVisible = ref(false);
    const formState = reactive({
      trackingNumber: "",
    });
    let rowRecord = null;
    const rulesRef = reactive({
      trackingNumber: [
        {
          required: true,
          message: "请在此处输入快递单号",
        },
      ],
    });
    const { resetFields, validate, validateInfos } = useForm(
      formState,
      rulesRef
    );

    const {
      search,
      refresh,
      exortTable,
      dataSourceRef,
      pagination,
      scroll,
      tableChange,
      editData,
      tableLoading,
      getData,
    } = useTable({
      get: "/background/userWinningInfo",
      export: "/background/downloadUserWinningInfo",
    });

    // 篩選數據
    const { filterState, filterFn } = useTableFilter(
      "/background/userWinningInfo",
      {
        userId: "",
        userName: "",
        winningTime: "",
        winningType: null,
      }
    );

    const openModal = (record) => {
      modalVisible.value = true;
      rowRecord = record;
      formState.trackingNumber = rowRecord.trackingNumber;
    };

    const modalOk = () => {
      validate()
        .then(() => {
          return addTrackingNumber({
            trackingNumber: formState.trackingNumber,
            id: rowRecord.id,
          });
        })
        .then(() => {
          message.success("上传成功快递单号成功！");
          refresh(filterState);
          modalVisible.value = false;
        })
        .catch(() => {});
    };

    const modalClose = () => {
      rowRecord = null;
      resetFields();
      modalVisible.value = false;
    };

    return {
      validateInfos,
      validate,
      formState,
      modalClose,
      openModal,
      modalOk,
      modalVisible,
      refresh,
      exortTable,
      dataSourceRef,
      filterState,
      search,
      filterFn,
      pagination,
      scroll,
      tableChange,
      editData,
      tableLoading,
      getData,
      columns: [
        {
          title: "用户ID",
          dataIndex: "userId",
          key: "userId",
          align: "center",
          width: 200,
          fixed: "left",
        },
        {
          title: "用户名称",
          dataIndex: "userName",
          key: "userName",
          align: "center",
          width: 150,
          fixed: "left",
        },
        {
          title: "收件人",
          dataIndex: "recipient",
          key: "recipient",
          align: "center",
          width: 150,
          fixed: "left",
        },
        {
          title: "电话号码",
          dataIndex: "phoneNumber",
          key: "phoneNumber",
          align: "center",
          width: 150,
        },
        {
          title: "地址",
          dataIndex: "address",
          key: "address",
          align: "center",
          width: 400,
        },
        {
          title: "中奖类型",
          dataIndex: "winningType",
          key: "winningType",
          align: "center",
          width: 150,
        },
        {
          title: "中奖名称",
          dataIndex: "winningType",
          key: "winningType",
          align: "center",
          width: 150,
          customRender: ({ text }) => {
            const typeText = {
              0: "排名奖励",
              1: "集装扮奖励",
              2: "盒子(DHA)",
              3: "盒子(新春福袋)",
            };
            return typeText[text];
          },
        },
        {
          title: "奖品数量",
          dataIndex: "winningType",
          key: "winningType",
          align: "center",
          width: 150,
          customRender: () => 1,
        },
        {
          title: "中奖时间",
          dataIndex: "createTime",
          key: "createTime",
          align: "center",
          width: 200,
        },
        {
          title: "物流单号",
          dataIndex: "trackingNumber",
          key: "trackingNumber",
          align: "center",
          width: 200,
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          fixed: "right",
          width: 150,
          slots: {
            customRender: "action",
          },
        },

        // {
        //   title: "创建时间",
        //   dataIndex: "createTime",
        //   key: "createTime",
        //   align: "center",
        //   width: 200,
        // },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
